import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import config from "../../config";
import axios from "axios";
const Completions = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [completions, setCompletions] = useState([]);

  useEffect(() => {
    axios.get(`${config.apiServer}/completions`).then((response) => {
      setCompletions(response.data);
    });
  }, []);

  const handleExpand = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  return (
    <Row>
      {completions.map((completion, index) => (
        <Col key={index} xs={12} className="mb-3">
          <div onClick={() => handleExpand(index)}>
            <strong>Project ID:</strong> {completion.projectId}
            <br />
            <strong>Prompt:</strong> {completion.prompt}
            <br />
            <strong>Completion:</strong>{" "}
            {expandedIndex === index
              ? completion.completion
              : `${completion.completion.slice(0, 50)}...`}
            {completion.completion.length > 50 && (
              <span style={{ cursor: "pointer" }}>
                {expandedIndex === index ? "...less" : "...more"}
              </span>
            )}
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default Completions;
