import React, { useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap"; // Assuming you are using React Bootstrap
import config from "../config";
import axios from "axios";

function TransactionEdit({ data, onCancel }) {
  // Create a copy of the incoming data to use as the initial state
  const [formData, setFormData] = useState(data);
  const [saving, setSaving] = useState(false); // State to track API request status

  // Event handler for input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    console.log(formData);
  };

  // Event handler for form submission
  const handleSubmit = () => {
    setSaving(true); // Set saving state to true when the API request starts

    axios
      .put(`${config.apiServer}/transactions/edit`, formData)
      .then((response) => {
        // Handle the successful response from the API
        setSaving(false); // Set saving state to false when the API request is complete
        onCancel(); // Close the modal
      })
      .catch((err) => {
        console.error(err);
        setSaving(false); // Set saving state to false in case of an error
      });
  };

  return (
    <Modal show={true} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formName">
            <Row>
              <Col>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </Col>
              <Col>
                <Form.Label>Wallet ID</Form.Label>
                <Form.Control
                  type="text"
                  name="walletId"
                  value={formData.walletId}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="text"
                  name="amount"
                  value={formData.amount}
                  onChange={handleInputChange}
                />
              </Col>
              <Col>
                <Form.Label>Net</Form.Label>
                <Form.Control
                  type="text"
                  name="net"
                  value={formData.net}
                  onChange={handleInputChange}
                />
              </Col>
              <Col>
                <Form.Label>fee</Form.Label>
                <Form.Control
                  type="text"
                  name="fee"
                  value={formData.fee}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>date</Form.Label>
                <Form.Control
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleInputChange}
                />
              </Col>
              <Col>
                <Form.Label>aba</Form.Label>
                <Form.Control
                  type="text"
                  name="aba"
                  value={formData.aba}
                  onChange={handleInputChange}
                />
              </Col>
              <Col>
                <Form.Label>account</Form.Label>
                <Form.Control
                  type="text"
                  name="account"
                  value={formData.account}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>email</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </Col>
              <Col>
                <Form.Label>phone</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
          </Form.Group>

          {/* Add more input fields for other properties */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={saving} // Disable the button while the API request is in progress
        >
          {saving ? "Saving..." : "Save Changes"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TransactionEdit;
