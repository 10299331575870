import "./App.css";
import React, { useState } from "react";
import axios from "axios";
import config from "./config";
import { Form, FormGroup, Input, Button, Label } from "reactstrap";
import DashboardPage from "./DashboardPage";

export default function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authenticated, setAuthenticated] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(true);

  async function handleLoginSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(`${config.apiServer}/auth`, {
        email: event.target.email.value,
        password: event.target.password.value,
      });
      //console.log("response", response);
      if (response.data && response.data.success) {
        setAuthenticated(true);
        console.log("authenticated", response.data);
      } else {
        setAuthenticated(false);
        alert(response.data.message);
      }
    } catch (err) {
      console.error(err);
      alert("API Call Failed", err);
    }
    setIsLoading(false);
  }

  function handleChange(event) {
    const { id, value } = event.target;

    if (id === "email") {
      setEmail(value);
    } else if (id === "password") {
      setPassword(value);
    }
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const onLogout = () => {
    setAuthenticated(false);
  };

  let LoginForm = (
    <div className="d-flex align-items-end justify-content-end vh-100 form-background">
      <Form onSubmit={handleLoginSubmit} className="custom-form-login">
        {/* <h3 style={{ weight: "bold", marginBottom: "2em" }}>Login</h3> */}
        <FormGroup>
          <Label>Email</Label>
          <Input
            autoFocus
            id="email"
            type="email"
            value={email}
            onChange={handleChange}
            placeholder="Enter email"
          />
        </FormGroup>
        <FormGroup>
          <Label>Password</Label>
          <i
            className={`fa ${passwordVisible ? "fa-eye-slash" : "fa-eye"}`}
            onClick={togglePasswordVisibility}
            style={{
              color: "#27ef0d",
              marginLeft: "10px",
              fontSize: "20px",
              cursor: "pointer",
            }} // Add inline styles here
          />
          <div
            className="password-input"
            style={{ display: "flex", alignitems: "center" }}
          >
            <Input
              id="password"
              value={password}
              onChange={handleChange}
              type={passwordVisible ? "text" : "password"}
              placeholder="Enter password"
              style={{ width: "300px", marginRight: "5px" }}
            />
          </div>
        </FormGroup>
        <br />
        <Button
          className="login-button"
          type="submit"
          outline
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            color: "#27ef0d",
          }}
          disabled={isLoading} // Disable the button while loading
        >
          Imagine
        </Button>
      </Form>
    </div>
  );

  return authenticated ? <DashboardPage onLogout={onLogout} /> : LoginForm;
}
