import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// Import useHistory from React Router

export default function TopbarDefault({ onLogout }) {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const navigate = useNavigate(); // Get the navigate function

  const handleLogout = () => {
    onLogout(); // Call the logout logic passed in as a prop
    navigate("/"); // Redirect to the root route
  };

  const handleChangePassword = () => {
    navigate("/change-password"); // Redirect to the change password route
  };

  return (
    <div className="float-right">
      {" "}
      {/* Ensure dropdown is aligned to the right */}
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret className="custom-dropdown-toggle">
          User Actions
        </DropdownToggle>
        <DropdownMenu right>
          {" "}
          {/* Align dropdown menu to the right */}
          <DropdownItem onClick={handleChangePassword}>
            Change Password
          </DropdownItem>
          <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

// {
//   <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
//     <div className="input-group">
//       <input
//         type="text"
//         className="form-control bg-light border-0 small"
//         placeholder="Search for..."
//         aria-label="Search"
//         aria-describedby="basic-addon2"
//       />
//       <div className="input-group-append">
//         <a className="btn btn-primary" href="index.html" type="button">
//           <i className="fas fa-search fa-sm"></i>
//         </a>
//       </div>
//     </div>
//   </form>;
// }

// {
//   <ul className="navbar-nav ml-auto">
//     <li className="nav-item dropdown no-arrow d-sm-none">
//       <a
//         className="nav-link dropdown-toggle"
//         href="index.html"
//         id="searchDropdown"
//         role="button"
//         data-toggle="dropdown"
//         aria-haspopup="true"
//         aria-expanded="false"
//       >
//         <i className="fas fa-search fa-fw"></i>
//       </a>
//       <div
//         className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
//         aria-labelledby="searchDropdown"
//       >
//         <form className="form-inline mr-auto w-100 navbar-search">
//           <div className="input-group">
//             <input
//               type="text"
//               className="form-control bg-light border-0 small"
//               placeholder="Search for..."
//               aria-label="Search"
//               aria-describedby="basic-addon2"
//             />
//             <div className="input-group-append">
//               <a className="btn btn-primary" href="index.html" type="button">
//                 <i className="fas fa-search fa-sm"></i>
//               </a>
//             </div>
//           </div>
//         </form>
//       </div>
//     </li>

//     <li className="nav-item dropdown no-arrow mx-1">
//       <a
//         className="nav-link dropdown-toggle"
//         href="index.html"
//         id="alertsDropdown"
//         role="button"
//         data-toggle="dropdown"
//         aria-haspopup="true"
//         aria-expanded="false"
//       >
//         <i className="fas fa-bell fa-fw"></i>
//         <span className="badge badge-danger badge-counter">3+</span>
//       </a>
//       <div
//         className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
//         aria-labelledby="alertsDropdown"
//       >
//         <h6 className="dropdown-header">Alerts Center</h6>
//         <a
//           className="dropdown-item d-flex align-items-center"
//           href="index.html"
//         >
//           <div className="mr-3">
//             <div className="icon-circle bg-primary">
//               <i className="fas fa-file-alt text-white"></i>
//             </div>
//           </div>
//           <div>
//             <div className="small text-gray-500">December 12, 2019</div>
//             <span className="font-weight-bold">
//               A new monthly report is ready to download!
//             </span>
//           </div>
//         </a>
//         <a
//           className="dropdown-item d-flex align-items-center"
//           href="index.html"
//         >
//           <div className="mr-3">
//             <div className="icon-circle bg-success">
//               <i className="fas fa-donate text-white"></i>
//             </div>
//           </div>
//           <div>
//             <div className="small text-gray-500">December 7, 2019</div>
//             $290.29 has been deposited into your account!
//           </div>
//         </a>
//         <a
//           className="dropdown-item d-flex align-items-center"
//           href="index.html"
//         >
//           <div className="mr-3">
//             <div className="icon-circle bg-warning">
//               <i className="fas fa-exclamation-triangle text-white"></i>
//             </div>
//           </div>
//           <div>
//             <div className="small text-gray-500">December 2, 2019</div>
//             Spending Alert: We've noticed unusually high spending for your
//             account.
//           </div>
//         </a>
//         <a
//           className="dropdown-item text-center small text-gray-500"
//           href="index.html"
//         >
//           Show All Alerts
//         </a>
//       </div>
//     </li>

//     <li className="nav-item dropdown no-arrow mx-1">
//       <a
//         className="nav-link dropdown-toggle"
//         href="index.html"
//         id="messagesDropdown"
//         role="button"
//         data-toggle="dropdown"
//         aria-haspopup="true"
//         aria-expanded="false"
//       >
//         <i className="fas fa-envelope fa-fw"></i>
//         <span className="badge badge-danger badge-counter">7</span>
//       </a>
//       <div
//         className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
//         aria-labelledby="messagesDropdown"
//       >
//         <h6 className="dropdown-header">Message Center</h6>
//         <a
//           className="dropdown-item d-flex align-items-center"
//           href="index.html"
//         >
//           <div className="dropdown-list-image mr-3">
//             <img
//               className="rounded-circle"
//               src="img/undraw_profile_1.svg"
//               alt="..."
//             />
//             <div className="status-indicator bg-success"></div>
//           </div>
//           <div className="font-weight-bold">
//             <div className="text-truncate">
//               Hi there! I am wondering if you can help me with a problem I've
//               been having.
//             </div>
//             <div className="small text-gray-500">Emily Fowler · 58m</div>
//           </div>
//         </a>
//         <a
//           className="dropdown-item d-flex align-items-center"
//           href="index.html"
//         >
//           <div className="dropdown-list-image mr-3">
//             <img
//               className="rounded-circle"
//               src="img/undraw_profile_2.svg"
//               alt="..."
//             />
//             <div className="status-indicator"></div>
//           </div>
//           <div>
//             <div className="text-truncate">
//               I have the photos that you ordered last month, how would you like
//               them sent to you?
//             </div>
//             <div className="small text-gray-500">Jae Chun · 1d</div>
//           </div>
//         </a>
//         <a
//           className="dropdown-item d-flex align-items-center"
//           href="index.html"
//         >
//           <div className="dropdown-list-image mr-3">
//             <img
//               className="rounded-circle"
//               src="img/undraw_profile_3.svg"
//               alt="..."
//             />
//             <div className="status-indicator bg-warning"></div>
//           </div>
//           <div>
//             <div className="text-truncate">
//               Last month's report looks great, I am very happy with the progress
//               so far, keep up the good work!
//             </div>
//             <div className="small text-gray-500">Morgan Alvarez · 2d</div>
//           </div>
//         </a>
//         <a
//           className="dropdown-item d-flex align-items-center"
//           href="index.html"
//         >
//           <div className="dropdown-list-image mr-3">
//             <img
//               className="rounded-circle"
//               src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
//               alt="..."
//             />
//             <div className="status-indicator bg-success"></div>
//           </div>
//           <div>
//             <div className="text-truncate">
//               Am I a good boy? The reason I ask is because someone told me that
//               people say this to all dogs, even if they aren't good...
//             </div>
//             <div className="small text-gray-500">Chicken the Dog · 2w</div>
//           </div>
//         </a>
//         <a
//           className="dropdown-item  text-center small text-gray-500 "
//           href="index.html"
//         >
//           Read More Messages
//         </a>
//       </div>
//     </li>

//     <div className="topbar-divider d-none d-sm-block"></div>

//     <li className="nav-item dropdown no-arrow">
//       <a
//         className="nav-link dropdown-toggle"
//         href="index.html"
//         id="userDropdown"
//         role="button"
//         data-toggle="dropdown"
//         aria-haspopup="true"
//         aria-expanded="false"
//       >
//         <span className="mr-2 d-none d-lg-inline text-gray-600 small">
//           Admin@marxgroupllc.com
//         </span>
//         <img
//           className="img-profile rounded-circle"
//           src="img/undraw_profile.svg"
//           alt="."
//         />
//       </a>
//       <div
//         className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
//         aria-labelledby="userDropdown"
//       >
//         <a className="dropdown-item" href="index.html">
//           <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
//           Profile
//         </a>
//         <a className="dropdown-item" href="index.html">
//           <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
//           Settings
//         </a>
//         <a className="dropdown-item" href="index.html">
//           <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
//           Activity Log
//         </a>
//         <div className="dropdown-divider"></div>
//         <a
//           className="dropdown-item"
//           href="index.html"
//           data-toggle="modal"
//           data-target="#logoutModal"
//         >
//           <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
//           Logout
//         </a>
//       </div>
//     </li>
//   </ul>;
// }
//       </nav>
//     </>
//   );
// }
