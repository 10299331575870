import React, { useState, useEffect } from "react";
import { Card, CardBody, CardTitle, CardText } from "reactstrap"; // Assuming you're using Reactstrap
import DetailedDisplay from "./DetailedDisplay"; // Adjust the import path as necessary
import config from "../../../config";

export default function Display() {
  const [selectedBook, setSelectedBook] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [books, setBooks] = useState([]);

  useEffect(() => {
    async function fetchBooks() {
      setIsLoading(true);
      try {
        const response = await fetch(`${config.apiServer}/books`);
        const data = await response.json();
        setBooks(data);
      } catch (err) {
        console.error("API Call Failed", err);
      }
      setIsLoading(false);
    }
    fetchBooks();
  }, []);

  if (selectedBook) {
    return <DetailedDisplay book={selectedBook} />;
  }

  return (
    <div>
      {/* Display loading indicator if isLoading is true */}
      {isLoading ? (
        <div>Loading...</div> // This could be replaced with a more sophisticated loading spinner or component
      ) : (
        books.map((book) => (
          <Card
            key={book.id}
            onClick={() => setSelectedBook(book)}
            style={{ cursor: "pointer" }}
          >
            <CardBody>
              <CardTitle>{book.title}</CardTitle>
              <CardText>Author: {book.author}</CardText>
              <CardText>Created: {book.created}</CardText>
            </CardBody>
          </Card>
        ))
      )}
    </div>
  );
}
