import React, { useState, useEffect } from "react";
import config from "../config";
import { Table, Button, Input, Row, Col } from "reactstrap";
import TransactionEdit from "./TransactionEdit";
import Papa from "papaparse";

const Search = () => {
  const [filterAll, setFilterAll] = useState(true);
  const [filterDeposits, setFilterDeposits] = useState(false);
  const [filterWithdrawals, setFilterWithdrawals] = useState(false);
  const [filteredData, setFilteredData] = useState([]); // State to store the filtered data
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState([]);
  const itemsPerPage = 10;
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // State to store the selected item

  // Function to open the TransactionEditor modal and set the selected id

  const exportToCsv = () => {
    // Replace 'filteredData' with your search results data
    const data = filteredData;

    if (!data || data.length === 0) {
      console.error("No data to export to CSV");
      return; // Exit the function
    }

    // Convert the data to CSV format
    const csv = Papa.unparse(data);

    // Create a Blob with the CSV data
    const blob = new Blob([csv], { type: "text/csv" });

    // Create a download link for the CSV file
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "search_results.csv";

    // Trigger the download
    link.click();
  };

  const openEditor = (item) => {
    console.log("openEditor", item);
    setSelectedItem(item);
    setModalVisible(true);
  };

  const closeEditor = () => {
    setModalVisible(false);
  };

  const handleFilterChange = (event) => {
    const selectedFilter = event.target.value;
    console.log("handleFilterChange", selectedFilter);

    // Toggle the selected filter and clear the others
    if (selectedFilter === "All") {
      setFilterAll(true);
      setFilterDeposits(false);
      setFilterWithdrawals(false);
      setFilteredData(data);
      setCurrentPage(1);
    } else if (selectedFilter === "Deposits") {
      setFilterAll(false);
      setFilterDeposits(true);
      setFilterWithdrawals(false);
      setFilteredData(
        data.filter((item) => item.transactionType === "deposit")
      );
      setCurrentPage(1);
    } else if (selectedFilter === "Withdrawals") {
      setFilterAll(false);
      setFilterDeposits(false);
      setFilterWithdrawals(true);
      setFilteredData(
        data.filter((item) => item.transactionType === "withdrawal")
      );
      setCurrentPage(1);
    } else if (selectedFilter === "") {
      setFilterAll(true);
      setFilterDeposits(false);
      setFilterWithdrawals(false);
      setFilteredData(data);
      setCurrentPage(1);
    }
    // Update the filter state
    setFilter((prevFilter) => {
      return [selectedFilter];
    });
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEnddateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleStartdateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handlePreviousClick = () => {
    // Scroll to the top of the window
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Update the current page
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    // Scroll to the top of the window
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Update the current page
    setCurrentPage(currentPage + 1);
  };

  const handleClear = (inputType) => {
    if (inputType === "name") {
      setName("");
    } else if (inputType === "sdate") {
      setStartDate("");
    } else if (inputType === "edate") {
      setEndDate("");
    }
    setCurrentPage(1);
  };

  const createURI = (name, startDate, endDate, filter) => {
    // Construct the URL with query parameters based on user input
    const searchParams = new URLSearchParams();
    if (name) {
      searchParams.append("name", name);
    }
    if (startDate) {
      searchParams.append("sdate", startDate);
    }
    if (endDate) {
      searchParams.append("edate", endDate);
    }
    if (filter) {
      searchParams.append("filterBy", filter);
    }

    // Use the constructed URL to make the API request
    const apiUrl = `/transactions/find?${searchParams.toString()}`;

    // Make a GET request to apiUrl to retrieve the search results
    console.log("apiUrl", apiUrl);
    return apiUrl;
  };

  useEffect(() => {
    let debounceTimer;
    //console.log("name: date", name, startDate, endDate);
    let url = createURI(name, startDate, endDate, filter);
    //console.log("url", url);
    const fetchData = async () => {
      try {
        const response = await fetch(`${config.apiServer}${url}`);
        if (!response.ok) {
          throw new Error("Unable to fetch data");
        }
        const responseData = await response.json();
        setData(responseData); // Update data with fetched data
        setFilteredData(responseData);
        setCurrentPage(1);
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setData([]); // Clear data in case of error
      }
    };

    if (name.length >= 3) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(fetchData, 300); // Adjust debounce delay as needed
    } else {
      setData([]); // Clear data if name is too short
      // Reset current page to the first page
    }
    fetchData();
    return () => clearTimeout(debounceTimer); // Clear timer on unmount
  }, [name, startDate, endDate, filter]); // Dependency: name, date

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems =
    data && data.length > 0 ? filteredData.slice(firstIndex, lastIndex) : [];

  return (
    <div>
      <Row>
        <h3>Search and Display Records</h3>
      </Row>

      {/* create a form to search for a name.  When the name is entered, the table should update with the results. */}
      <form>
        <Row>
          <Col>
            <div className="input-container">
              <label>Name:</label>
              <Input type="text" value={name} onChange={handleNameChange} />
              {name && (
                <Button
                  type="button"
                  className="clear-button"
                  onClick={() => handleClear("name")}
                >
                  Clear
                </Button>
              )}
            </div>
          </Col>
          <Col>
            <div className="input-container">
              <label>Starting Date:</label>
              <Input
                type="date"
                value={startDate}
                onChange={handleStartdateChange}
              />
              {startDate && (
                <Button
                  type="button"
                  className="clear-button"
                  onClick={() => handleClear("sdate")}
                >
                  Clear
                </Button>
              )}
            </div>
          </Col>
          <Col>
            <div className="input-container">
              <label>End Date:</label>
              <Input
                type="date"
                value={endDate}
                onChange={handleEnddateChange}
              />
              {endDate && (
                <Button
                  type="button"
                  className="clear-button"
                  onClick={() => handleClear("edate")}
                >
                  Clear
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={8}></Col>
          <Col sm={1}>
            <div className="input-container">
              <label className="filter-label">Filter By:</label>
            </div>
          </Col>
          <Col sm={3}>
            <div className="input-container">
              <select
                className="filter-select"
                value={
                  filterAll
                    ? "All"
                    : filterDeposits
                    ? "Deposits"
                    : filterWithdrawals
                    ? "Withdrawals"
                    : ""
                }
                onChange={handleFilterChange}
              >
                <option value="All">All</option>
                <option value="Deposits">Deposits</option>
                <option value="Withdrawals">Withdrawals</option>
              </select>
            </div>
          </Col>
        </Row>
      </form>
      <div>
        <br />
      </div>

      {/* create the table to display the results. */}
      {/* Pagination controls */}
      <Row>
        <div className="d-flex justify-content-start align-items-center">
          <Col sm={2}>
            <Button
              color="primary"
              size="sm"
              onClick={handlePreviousClick}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
          </Col>
          <Col sm={1}>
            <span className="text-center mx-2">{currentPage}</span>
          </Col>
          <Col sm={2}>
            <Button
              color="primary"
              size="sm"
              onClick={handleNextClick}
              disabled={currentItems.length < itemsPerPage}
            >
              Next
            </Button>
          </Col>
          <Col sm={4}>
            <div className="ml-4" style={{ fontWeight: "bold" }}>
              Results : {filteredData.length}
            </div>
          </Col>
          <Col sm={3}>
            {filteredData.length > 0 ? (
              <Button color="primary" outline size="sm" onClick={exportToCsv}>
                Download
              </Button>
            ) : (
              <Button color="primary" outline size="sm" disabled>
                Download
              </Button>
            )}
          </Col>
        </div>
      </Row>
      <Table>
        {/* Table headers */}
        <thead></thead>
        {/* Table body */}
        <tbody>
          {currentItems.map((item, index) => (
            <React.Fragment key={index}>
              {/* Row 1: Basic Information */}
              <tr>
                <td colSpan="5">
                  <strong>{item.name}</strong> - {item.date} - {item._id}
                </td>
                <td>
                  <button onClick={() => openEditor(item)}>Edit</button>
                </td>
              </tr>
              {/* Row 2: Payment Details */}
              <tr>
                <td>Wallet ID: {item.walletId}</td>
                <td>Payment Method: {item.paymentMethod}</td>
                <td>
                  Amount:{" "}
                  {item.amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </td>
                <td>
                  Fee:{" "}
                  {item.fee.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </td>
                <td>
                  Net:{" "}
                  {item.net.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </td>
                <td colSpan="2">Transaction Type: {item.transactionType}</td>
              </tr>
              {/* Row 3: Account Information */}
              <tr>
                <td>ABA: {item.aba}</td>
                <td>Account: {item.account}</td>
                <td>Email: {item.email}</td>
                <td>Phone: {item.phone}</td>
              </tr>
              {/* Row 4: Address */}
              {/* <tr>
                <td>Street: {item.street}</td>
                <td>City: {item.city}</td>
                <td>State: {item.state}</td>
                <td>Zip: {item.zip}</td>
              </tr> */}
              {/* Empty row for separation */}
              <tr>
                <td colSpan="5"></td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      {modalVisible && selectedItem !== null && (
        <TransactionEdit data={selectedItem} onCancel={closeEditor} />
      )}
      <Row>
        <div className="d-flex justify-content-start align-items-center">
          <Col sm={3}>
            <Button
              color="primary"
              size="sm"
              onClick={handlePreviousClick}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
          </Col>
          <Col sm={1}>
            <span className="text-center mx-2">{currentPage}</span>
          </Col>
          <Col sm={2}>
            <Button
              color="primary"
              size="sm"
              onClick={handleNextClick}
              disabled={currentItems.length < itemsPerPage}
            >
              Next
            </Button>
          </Col>
          <Col sm={6}>
            <div className="ml-4" style={{ fontWeight: "bold" }}>
              Results : {filteredData.length}
            </div>
          </Col>
        </div>
      </Row>
    </div>
  );
};

export default Search;
