export default function Footer() {
  let year = new Date().getFullYear();
  return (
    <>
      <footer className="sticky-footer bg-white">
        <div className="container my-auto">
          <div className="copyright text-center my-auto">
            <span>Copyright &copy; Chris Ranni {year}</span>
          </div>
        </div>
      </footer>
    </>
  );
}
