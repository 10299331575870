import React, { useState } from "react";
import SelectBookTypeForm from "./SelectBookTypeForm";
import CreateProjectNameForm from "./CreateProjectNameForm";

const CreateBookWizard = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [projectData, setProjectData] = useState({
    title: "",
    type: "",
    // You can add more fields as needed
  });

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  // Handlers for form inputs, e.g., updating state on input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectData({ ...projectData, [name]: value });
  };

  // Placeholder for now, will be implemented later
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(projectData);
    // Here you would send data to your API to create the project
  };

  // Render function that conditionally renders the current step
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <SelectBookTypeForm
            onChange={handleChange}
            projectData={projectData}
          />
        );
      case 2:
        return (
          <CreateProjectNameForm
            onChange={handleChange}
            projectData={projectData}
          />
        );
      // Add more cases for additional steps
      default:
        return <div>Review & Submit</div>;
    }
  };

  return (
    <div>
      <h2>Create Book Project</h2>
      {renderStep()}
      {currentStep > 1 && <button onClick={prevStep}>Previous</button>}
      {currentStep < 3 ? ( // Adjust based on the number of steps
        <button onClick={nextStep}>Next</button>
      ) : (
        <button onClick={handleSubmit}>Submit</button>
      )}
    </div>
  );
};

export default CreateBookWizard;
