import React, { useState, useEffect } from "react";
import config from "../../config";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
} from "reactstrap";

import { FaTimes } from "react-icons/fa";

const Todos = () => {
  const [todos, setTodos] = useState([]);
  const [modal, setModal] = useState(false);
  const [newTodo, setNewTodo] = useState({
    title: "",
    assignTo: "Chris Ranni",
    priority: "low",
    description: "",
  });

  useEffect(() => {
    // Fetch todo list from the database
    const fetchTodos = async () => {
      try {
        const response = await fetch(`${config.apiServer}/todos`);
        const data = await response.json();
        setTodos(data);
        console.log("Todos fetched successfully:", data);
      } catch (error) {
        console.error("Error fetching todos:", error);
      }
    };

    fetchTodos();
  }, []);

  const toggleModal = () => setModal(!modal);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTodo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDragEnd = async (result) => {
    const { source, destination } = result;

    // Do nothing if dropped outside the list or no change
    if (!destination) {
      return;
    }

    const start = source.droppableId; // The list from which the item was dragged
    const finish = destination.droppableId; // The list in which the item was dropped

    // Moving items within the same list
    if (start === finish) {
      const newList = Array.from(todos);
      const [relocatedItem] = newList.splice(source.index, 1);
      newList.splice(destination.index, 0, relocatedItem);

      setTodos(newList);
    } else {
      // Moving items between different lists
      const sourceList = Array.from(
        todos.filter((todo) => todo.status === start)
      );
      const [removedItem] = sourceList.splice(source.index, 1);
      const destinationList = Array.from(
        todos.filter((todo) => todo.status === finish)
      );
      destinationList.splice(destination.index, 0, removedItem);

      // Update the status of the moved item
      const updatedItem = { ...removedItem, status: finish };

      // Update state to reflect the move between lists
      const updatedTodos = todos.map((todo) =>
        todo._id === updatedItem._id ? updatedItem : todo
      );

      setTodos(updatedTodos);

      try {
        const response = await fetch(
          `${config.apiServer}/todos/${updatedItem._id}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedItem),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }

        console.log("Todo updated successfully:", await response.json());
      } catch (error) {
        console.error("Failed to update todo:", error);
      }
    }
  };

  const handleNewTodoSubmit = async (e) => {
    console.log("Adding new todo:", newTodo);
    e.preventDefault();
    if (!newTodo.title.trim()) {
      return;
    }

    try {
      const response = await fetch(`${config.apiServer}/todos`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: newTodo.title,
          status: "pending",
          date: new Date(),
          description: newTodo.description,
          assignTo: newTodo.assignTo,
          priority: newTodo.priority,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const addedTodo = await response.json(); // Renamed variable to avoid shadowing outer scope
      setTodos([...todos, addedTodo]);

      // Reset the newTodo state if needed
      setNewTodo({
        title: "",
        assignTo: "Chris Ranni",
        priority: "low",
        description: "",
      });

      toggleModal(); // Close the modal after successful submission
    } catch (error) {
      console.error("Failed to create new todo:", error);
    }
  };

  const handleDeleteTodo = async (todoId) => {
    console.log("Deleting todo:", todoId);
    try {
      const response = await fetch(`${config.apiServer}/todos/${todoId}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      setTodos(todos.filter((todo) => todo._id !== todoId));
    } catch (error) {
      console.error("Failed to delete todo:", error);
    }
  };

  return (
    <>
      <Row>
        <Button color="primary" onClick={toggleModal}>
          Add New Todo
        </Button>
      </Row>
      <br />
      <Row>
        <Modal isOpen={modal} toggle={toggleModal}>
          <Form onSubmit={handleNewTodoSubmit}>
            <ModalHeader toggle={toggleModal}>Add New Todo</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="title">Title</Label>
                <Input
                  id="title"
                  name="title"
                  value={newTodo.title}
                  onChange={handleInputChange}
                  type="text"
                  placeholder="New Todo"
                />
              </FormGroup>
              <FormGroup>
                <Label for="assignTo">Assigned To</Label>
                <Input
                  id="assignTo"
                  name="assignTo"
                  value={newTodo.assignTo}
                  onChange={handleInputChange}
                  type="text"
                  placeholder="Assignee Name"
                />
              </FormGroup>
              <FormGroup>
                <Label for="description">Description</Label>
                <Input
                  id="description"
                  name="description"
                  value={newTodo.description}
                  onChange={handleInputChange}
                  type="textarea"
                  placeholder="Description"
                />
              </FormGroup>
              <FormGroup>
                <Label for="priority">Priority</Label>
                <Input
                  id="priority"
                  name="priority"
                  value={newTodo.priority}
                  onChange={handleInputChange}
                  type="select"
                >
                  <option value="low">Low</option>
                  <option value="medium">Medium</option>
                  <option value="high">High</option>
                </Input>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" type="submit">
                Add Todo
              </Button>{" "}
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Row>

      <DragDropContext onDragEnd={handleDragEnd}>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Droppable
            key="pending"
            droppableId="pending"
            direction="vertical"
            isDropDisabled={false}
          >
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ flex: 1, margin: "0 10px" }}
              >
                <h4>Pending</h4>
                {todos
                  .filter((todo) => todo.status === "pending")
                  .map((item, index) => {
                    // Determine background color based on priority
                    const backgroundColor =
                      item.priority === "low"
                        ? "lightgrey"
                        : item.priority === "medium"
                        ? "yellow"
                        : "lightcoral"; // Assuming "high" priority is left

                    return (
                      <Draggable
                        key={item._id}
                        draggableId={item._id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              marginBottom: "8px",
                              backgroundColor: backgroundColor,
                              padding: "10px",
                              borderRadius: "5px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignitems: "center",
                            }}
                          >
                            {item.title}

                            <Button
                              color="danger"
                              className="btn-icon"
                              onClick={() => handleDeleteTodo(item._id)}
                            >
                              <FaTimes />
                            </Button>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <Droppable
            key="inProgress"
            droppableId="in progress"
            direction="vertical"
            isDropDisabled={false}
          >
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ flex: 1, margin: "0 10px" }}
              >
                <h4>In Progress</h4>
                {todos
                  .filter((todo) => todo.status === "in progress")
                  .map((item, index) => (
                    <Draggable
                      key={item._id}
                      draggableId={item._id.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            marginBottom: "8px",
                            backgroundColor: "lightblue",
                            padding: "10px",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignitems: "center",
                          }}
                        >
                          {item.title}
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <Droppable
            key="completed"
            droppableId="completed"
            direction="vertical"
            isDropDisabled={false}
          >
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ flex: 1, margin: "0 10px" }}
              >
                <h4>Completed</h4>
                {todos
                  .filter((todo) => todo.status === "completed")
                  .map((item, index) => (
                    <Draggable
                      key={item._id}
                      draggableId={item._id.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            marginBottom: "8px",
                            backgroundColor: "lightgrey",
                            padding: "10px",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignitems: "center",
                          }}
                        >
                          {item.title}
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </>
  );
};

export default Todos;
