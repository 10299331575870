import { NavLink, Link } from "react-router-dom";
import {
  FcTodoList,
  FcReading,
  FcIdea,
  FcCircuit,
  FcMindMap,
  FcEnteringHeavenAlive,
  FcAddressBook,
} from "react-icons/fc";
import thinker from "./Images/thinker.webp";
import {} from "react-icons/fc";
export default function Sidebar() {
  return (
    <>
      <ul
        className="navbar-nav bg-gradient-light sidebar sidebar-light accordion"
        id="accordionSidebar"
      >
        <Link
          className="sidebar-brand d-flex align-items-center justify-content-center"
          to="/home"
        >
          <div className="sidebar-brand-icon flip-horizontal">
            <img src={thinker} alt="logo" width="60px" height="60px" />
          </div>
          <div className="sidebar-brand-text mx-3">Thinking!</div>
        </Link>
        <hr className="sidebar-divider my-0" />
        {/* <li className="nav-item active">
          <Link to="/Dashboard" className="nav-link" href="index.html">
            <i className="fas fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </Link>
        </li> */}
        {/* <li className="nav-item active">
          <Link to="/search" className="nav-link" href="index.html">
            <i className="fas fa-fw fa-tachometer-alt"></i>
            <span>Search</span>
          </Link>
        </li>
        <hr className="sidebar-divider" />

        <div className="sidebar-heading">Interface</div> */}
        {/* <li className="nav-item">
          <Link
            to="/search"
            className="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseUtilities"
            aria-expanded="true"
            aria-controls="collapseUtilities"
          >
            <i className="fas fa-solid fa-binoculars"></i>

            <span>Search</span>
          </Link>
        </li> */}

        <li className="nav-item">
          <NavLink
            to="/todos"
            className={({ isActive }) =>
              isActive ? "nav-link collapsed active" : "nav-link collapsed"
            }
          >
            <FcTodoList />
            <span> Todos</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/books"
            className={({ isActive }) =>
              isActive ? "nav-link collapsed active" : "nav-link collapsed"
            }
          >
            <FcReading />
            <span> Books</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/openai"
            className={({ isActive }) =>
              isActive ? "nav-link collapsed active" : "nav-link collapsed"
            }
          >
            <FcIdea />
            <span> OpenAI</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/mail"
            className={({ isActive }) =>
              isActive ? "nav-link collapsed active" : "nav-link collapsed"
            }
          >
            <FcAddressBook />
            <span> Mail</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/display"
            className={({ isActive }) =>
              isActive ? "nav-link collapsed active" : "nav-link collapsed"
            }
          >
            <FcEnteringHeavenAlive />
            <span> Display</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/completions"
            className={({ isActive }) =>
              isActive ? "nav-link collapsed active" : "nav-link collapsed"
            }
          >
            <FcCircuit />
            <span> Completions</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/metrics"
            className={({ isActive }) =>
              isActive ? "nav-link collapsed active" : "nav-link collapsed"
            }
          >
            <FcMindMap />
            <span> Metrics</span>
          </NavLink>
        </li>

        {/* <hr className="sidebar-divider" />

         <div className="text-center d-none d-md-inline">
            <a className="rounded-circle border-0" href="index1.html" id="sidebarToggle">  </a>
         </div> */}
        {/* <div className="sidebar-card d-none d-lg-flex">
               <img className="sidebar-card-illustration mb-2" src="img/undraw_rocket.svg" alt="..." />
               <p className="text-center mb-2"><strong>SB Admin Pro</strong> is packed with premium features, components, and more!</p>
               <a className="btn btn-success btn-sm" href="https://startbootstrap.com/theme/sb-admin-pro">Upgrade to Pro!</a>
         </div> */}
      </ul>
    </>
  );
}
