import React, { useState } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";

const BookDisplay = ({ book }) => {
  const [title, setTitle] = useState(book.title);
  const [targetAge, setTargetAge] = useState(book.targetAge);
  const [characters, setCharacters] = useState(book.characters);
  const [theme, setTheme] = useState(book.theme);
  const [targetEmotions, setTargetEmotions] = useState(book.targetEmotions);
  const [directive, setDirective] = useState(book.directive);
  const [action, setAction] = useState(book.action);
  const [pages, setPages] = useState(book.pages);
  const [pageCount, setPageCount] = useState(book.pageCount);
  const [language, setLanguage] = useState(book.language);
  const [accessibilityFeatures, setAccessibilityFeatures] = useState(
    book.accessibilityFeatures
  );
  const [publicationDate, setPublicationDate] = useState(book.publicationDate);
  const [isbn, setIsbn] = useState(book.isbn);
  const [author, setAuthor] = useState(book.author);
  const [illustrator, setIllustrator] = useState(book.illustrator);
  const [reviews, setReviews] = useState(book.reviews);
  const [awards, setAwards] = useState(book.awards);
  const [recommendedAgeRange, setRecommendedAgeRange] = useState(
    book.recommendedAgeRange
  );
  const [digitalVersionAvailable, setDigitalVersionAvailable] = useState(
    book.digitalVersionAvailable
  );
  const [seriesTitle, setSeriesTitle] = useState(book.seriesTitle);
  const [description, setDescription] = useState(book.description);

  const handleSubmit = (e) => {
    e.preventDefault();
    // update book in database with new values
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Label for="title">Title</Label>
        <Input
          type="text"
          name="title"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="targetAge">Target Age</Label>
        <Input
          type="select"
          name="targetAge"
          id="targetAge"
          value={targetAge}
          onChange={(e) => setTargetAge(e.target.value)}
        >
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="characters">Characters</Label>
        <Input
          type="text"
          name="characters"
          id="characters"
          value={characters}
          onChange={(e) => setCharacters(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="theme">Theme</Label>
        <Input
          type="text"
          name="theme"
          id="theme"
          value={theme}
          onChange={(e) => setTheme(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="targetEmotions">Target Emotions</Label>
        <Input
          type="select"
          name="targetEmotions"
          id="targetEmotions"
          value={targetEmotions}
          onChange={(e) => setTargetEmotions(e.target.value)}
        >
          <option value="sharing">Sharing</option>
          <option value="jealousy">Jealousy</option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="directive">Directive</Label>
        <Input
          type="text"
          name="directive"
          id="directive"
          value={directive}
          onChange={(e) => setDirective(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="action">Action</Label>
        <Input
          type="text"
          name="action"
          id="action"
          value={action}
          onChange={(e) => setAction(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="pages">Pages</Label>
        <Input
          type="text"
          name="pages"
          id="pages"
          value={pages}
          onChange={(e) => setPages(e.target.value)}
        />
        {pages.length > 0 && (
          <div>
            {pages.map((page) => (
              <div key={page.pageNumber}>
                <p>Page Number: {page.pageNumber}</p>
                <p>Text: {page.text}</p>
                <p>Illustration: {page.illustration}</p>
                {page.image && <img src={page.image} alt="Story" />}
              </div>
            ))}
          </div>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="pageCount">Page Count</Label>
        <Input
          type="number"
          name="pageCount"
          id="pageCount"
          value={pageCount}
          onChange={(e) => setPageCount(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="language">Language</Label>
        <Input
          type="text"
          name="language"
          id="language"
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="accessibilityFeatures">Accessibility Features</Label>
        <Input
          type="text"
          name="accessibilityFeatures"
          id="accessibilityFeatures"
          value={accessibilityFeatures}
          onChange={(e) => setAccessibilityFeatures(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="publicationDate">Publication Date</Label>
        <Input
          type="date"
          name="publicationDate"
          id="publicationDate"
          value={publicationDate}
          onChange={(e) => setPublicationDate(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="isbn">ISBN</Label>
        <Input
          type="text"
          name="isbn"
          id="isbn"
          value={isbn}
          onChange={(e) => setIsbn(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="author">Author</Label>
        <Input
          type="text"
          name="author"
          id="author"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="illustrator">Illustrator</Label>
        <Input
          type="text"
          name="illustrator"
          id="illustrator"
          value={illustrator}
          onChange={(e) => setIllustrator(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="reviews">Reviews</Label>
        <Input
          type="text"
          name="reviews"
          id="reviews"
          value={reviews}
          onChange={(e) => setReviews(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="awards">Awards</Label>
        <Input
          type="text"
          name="awards"
          id="awards"
          value={awards}
          onChange={(e) => setAwards(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="recommendedAgeRange">Recommended Age Range</Label>
        <Input
          type="text"
          name="recommendedAgeRange"
          id="recommendedAgeRange"
          value={recommendedAgeRange}
          onChange={(e) => setRecommendedAgeRange(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="digitalVersionAvailable">Digital Version Available</Label>
        <Input
          type="checkbox"
          name="digitalVersionAvailable"
          id="digitalVersionAvailable"
          checked={digitalVersionAvailable}
          onChange={(e) =>
            setDigitalVersionAvailable(e.target.checked ? true : false)
          }
        />
      </FormGroup>
      <FormGroup>
        <Label for="seriesTitle">Series Title</Label>
        <Input
          type="text"
          name="seriesTitle"
          id="seriesTitle"
          value={seriesTitle}
          onChange={(e) => setSeriesTitle(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="description">Description</Label>
        <Input
          type="textarea"
          name="description"
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </FormGroup>
      <Button type="submit">Save Changes</Button>
    </Form>
  );
};

export default BookDisplay;
