import React, { useState, useEffect } from "react";
import config from "../../config";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";

const Home = () => {
  const [metrics, setMetrics] = useState({
    tokensUsed: 0,
    totalQueries: 0,
    averageResponseTime: 0,
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchMetrics = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${config.apiServer}/metrics`);
        const data = await response.json();
        setMetrics(data);
        console.log("Metrics:", data);
      } catch (error) {
        console.error("Failed to fetch metrics:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMetrics();
  }, []);

  return (
    <Container className="mt-5">
      <Row>
        <Col md="6" className="offset-md-3">
          <Card>
            <CardBody>
              <CardTitle tag="h5">API Metrics</CardTitle>
              {isLoading ? (
                <CardText>Loading metrics...</CardText>
              ) : (
                <>
                  <CardText>Total Tokens Used: {metrics.tokensUsed}</CardText>
                  <CardText>Total Queries: {metrics.totalQueries}</CardText>
                  <CardText>
                    Average Response Time: {metrics.averageResponseTime} ms
                  </CardText>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
