import React, { useRef } from "react";
import { Button, FormGroup, Label, Row, Col } from "reactstrap";

const FileInputButton = ({ handleFileSelect }) => {
  const fileInputRef = useRef(null);

  // Function to trigger file input dialog
  const handleClick = () => {
    fileInputRef.current.click();
  };

  // New function to handle file selection
  const handleFileChange = (event) => {
    // Check if any files were selected
    if (event.target.files.length > 0) {
      // Ensure the file name is accessible
      console.log("Selected file:", event.target.files[0].name);
      handleFileSelect(event);
    } else {
      // Log or handle the case where no file was selected
      console.log(event.target.files);
    }
  };

  return (
    <div>
      <FormGroup>
        <Row>
          <Label for="promptFile">
            Include a file that helps explain the code you want. Like an express
            route or model schema. (Optional)
          </Label>
          <Col sm={12}>
            <input
              type="file"
              name="file"
              id="promptFile"
              ref={fileInputRef}
              style={{ display: "none" }} // Hide the default input
              onChange={handleFileChange} // Corrected to use handleFileChange
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Button color="primary" outline onClick={handleClick}>
              Choose File
            </Button>
          </Col>
        </Row>
      </FormGroup>
    </div>
  );
};

export default FileInputButton;
