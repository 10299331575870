import React, { useState, useEffect } from "react";
import {
  FcViewDetails,
  FcEmptyTrash,
  FcNext,
  FcPrevious,
} from "react-icons/fc";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import config from "../../config";

const Mail = () => {
  const [emails, setEmails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(20);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentEmailContent, setCurrentEmailContent] = useState("");

  useEffect(() => {
    fetchEmails("mail.chrisranni.com");
  }, []);

  const fetchEmails = async (bucketName, prefix = "") => {
    try {
      const response = await axios.get(`${config.apiServer}/mail/items`);
      setEmails(response.data);
    } catch (error) {
      console.error("Failed to fetch emails:", error);
    }
  };

  const handleNextPage = () => {
    setCurrentPage((current) => current + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((current) => Math.max(current - 1, 1));
  };

  const handleOpenEmail = async (key) => {
    try {
      const response = await axios.get(
        `${config.apiServer}/mail/email?key=${key}`
      );
      setCurrentEmailContent(response.data.Body); // Assuming 'Body' contains the email content
      setIsModalOpen(true);
    } catch (error) {
      console.error("Failed to fetch email content:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteEmail = async (key) => {
    console.log("Deleting email:", key);
    try {
      await axios.delete(`${config.apiServer}/mail/email?key=${key}`);
      setEmails(emails.filter((email) => email.Key !== key)); // Remove the deleted email from the list
    } catch (error) {
      console.error("Failed to delete email:", error);
    }
  };

  const paginatedEmails = emails.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  // console.log("Paginated emails:", paginatedEmails);

  return (
    <Container>
      <Row>
        <Col>
          <h4>Emails</h4>
        </Col>
      </Row>
      <Row className="justify-content-center" alignitems="center">
        <Col md="auto">
          {" "}
          {/* For horizontal centering, if needed */}
          <span
            onClick={handlePreviousPage}
            style={{ cursor: "pointer", marginRight: "20px" }}
          >
            <FcPrevious size={24} /> Prev
          </span>
          <span onClick={handleNextPage} style={{ cursor: "pointer" }}>
            Next <FcNext size={24} />
          </span>
        </Col>
      </Row>
      <Row>
        {/* Column Labels */}
        <Col>Date</Col>
        <Col>From</Col>
        <Col>Subject</Col>
        <Col>Actions</Col>
      </Row>
      {paginatedEmails.map((email, index) => (
        <Row
          key={email.Key}
          style={{
            cursor: "pointer",
            backgroundColor: index % 2 === 0 ? "lightgray" : "white",
            fontSize: "10px",
          }}
          onMouseOver={() => {}}
        >
          <Col>{email.Date}</Col>
          <Col>{email.From}</Col>
          <Col>{email.Subject}</Col>
          <Col>
            <span
              onClick={() => handleOpenEmail(email.Key)}
              style={{ cursor: "pointer", marginRight: "20px" }} // Increased from 10px to 20px for more space
            >
              <FcViewDetails size={24} title="Open Email" />
            </span>
            <span
              onClick={() => handleDeleteEmail(email.Key)}
              style={{ cursor: "pointer", marginLeft: "20px" }} // Added marginLeft to the second span
            >
              <FcEmptyTrash size={24} title="Delete Email" />
            </span>
          </Col>
        </Row>
      ))}

      {/* Email Content Modal */}
      <Modal isOpen={isModalOpen} toggle={handleCloseModal} size="lg">
        <ModalHeader toggle={handleCloseModal}>Email Content</ModalHeader>
        <ModalBody>
          <div dangerouslySetInnerHTML={{ __html: currentEmailContent }} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default Mail;
