import React, { useState, useEffect } from "react";
import config from "../../config";
import FileInputButton from "../../Components/FileInputButton";

import {
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  InputGroup,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";

// import "./Form.css";

export default function OpenAI() {
  const [prompt, setPrompt] = useState("");
  const [temperature, setTemperature] = useState(0);
  const [maxTokens] = useState(2048);
  const [completion, setCompletion] = useState("");
  const [filename, setFilename] = useState("");
  const [model] = useState("gpt-3.5-turbo-instruct");
  const [modal, setModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const toggle = () => setModal(!modal);

  const initialState = {
    prompt: "",
  };

  useEffect(() => {
    if (completion) {
      setModal(true);
    }
  }, [completion]); // Only re-run the effect if `completion` changes

  // const handleSaveToClipboard = () => {
  //   navigator.clipboard.writeText(completion);
  // };

  const handleFileSelect = (event) => {
    // Get the file from the input
    const file = event.target.files[0];

    // Set the file object in the state
    setSelectedFile(file);
  };

  const handleSaveToFile = () => {
    // Create a Blob from the text
    const blob = new Blob([completion], { type: "text/plain;charset=utf-8" });

    // Create an anchor element and attach the Blob as its href
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;

    // Set the download attribute to specify the file name
    link.download = "completion.txt";

    // Append the anchor to the DOM, trigger click (to open the download dialog), and remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Release the URL
    window.URL.revokeObjectURL(url);
  };

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(model, prompt, temperature, maxTokens);
    try {
      const response = await fetch(`${config.apiServer}/openai`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          filename,
          selectedFile,
          model,
          prompt,
          temperature,
          max_tokens: maxTokens,
        }),
      });
      const data = await response.json();
      setCompletion(data);
      setPrompt(initialState.prompt);
      setTemperature(initialState.temperature);
      //setCompletion(initialState.completion);
    } catch (error) {
      console.error(error);
    }
  };
  const wordCount = prompt.trim().split(/\s+/).length;
  const isOverLimit = wordCount > maxTokens;

  return (
    <Container fluid>
      <Label style={{ color: "blue", textAlign: "center" }}></Label>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col sm={{ size: 6 }}>
            <FormText>
              This is the destination on the host server. Ex.
              "[Project]/ui/src/Components/Charts/index.js"
            </FormText>
            <FormGroup>
              <Label for="filename">Filename:</Label>
              <Input
                type="text"
                name="filename"
                id="filename"
                value={filename}
                onChange={(e) => setFilename(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <FormGroup>
              <Label for="temperature">Temperature:</Label>
              <Input
                type="text"
                name="temperature"
                id="temperature"
                value={temperature}
                placeholder="0, 0.1, 0.2, ..., 1.0"
                onChange={(e) => setTemperature(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <FileInputButton handleFileSelect={handleFileSelect} />
        {/* <CheckboxForm /> */}

        <br />
        <Row>
          <Col sm={{ size: 12 }}>
            <FormGroup>
              <Label for="prompt">Prompt:</Label>
              <InputGroup>
                <Input
                  type="textarea"
                  name="prompt"
                  id="prompt"
                  value={prompt}
                  onChange={handlePromptChange}
                  style={{ height: "170px" }}
                  placeholder="Optimal examples would be using pysudo code to describe the code you want to generate. so just an example. 'write a express route that handles the input parameters of .. and then perhaps a json object of those params. Include the schema of the db you'll be talking to.. ' The more detail the better the response."
                />
                {/*<InputGroupAddon addonType="append">expand</InputGroupAddon>*/}
              </InputGroup>
              <div
                style={{
                  marginTop: "5px",
                  fontSize: "12px",
                  color: isOverLimit ? "red" : "grey",
                }}
              >
                tokens: {wordCount}
                <span style={{ color: "grey" }}> / </span> remaining:
                {maxTokens - wordCount}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Button
          type="submit"
          color="primary"
          style={{ backgroundColor: "transparent", color: "black" }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Form>
      {completion && (
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Completion</ModalHeader>
          <ModalBody>
            <Alert color="success">
              <pre>
                <code>{completion}</code>
              </pre>
            </Alert>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              Close
            </Button>
            <Button
              style={{ backgroundColor: "transparent", color: "black" }}
              onClick={handleSaveToFile}
            >
              Save to file
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Container>
  );
}
