import React from "react";
import { Form, Label } from "reactstrap";
import Select from "react-select";
const SelectBookTypeForm = ({ onChange, projectData }) => {
  return (
    <Form>
      <Label>
        Book Type:
        <Select name="type" value={projectData.type} onChange={onChange}>
          <option value="childrensBook">Children's Book</option>
          <option value="tutorial">Tutorial</option>
          <option value="other">Other</option>
        </Select>
      </Label>
    </Form>
  );
};

export default SelectBookTypeForm;
