import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";

import OpenAI from "./Containers/OpenAI";
import Search from "./Search/index.js";
import Mail from "./Containers/Mail";
import Books from "./Containers/Books";
import Wizard from "./Containers/Books/Wizard/";
import Todos from "./Containers/Todos/Todos.js";
import Display from "./Containers/Books/Display";
import Completions from "./Containers/Completions";
// import SidebarSearch from "./Components/Sidebars/SidebarSearch";
// import SidebarTodo from "./Components/Sidebars/SidebarTodo";
// import SidebarMail from "./Components/Sidebars/SidebarMail";
// import SidebarBooks from "./Components/Sidebars/SidebarBooks";
// import SidebarDisplay from "./Components/Sidebars/SidebarDisplay";
import Sidebar from "./sidebar";
import Topbar from "./topbar";
import Footer from "./footer";
import Home from "./Containers/Home";
import Metrics from "./Containers/Metrics";
import ChangePassword from "./Components/ChangePassword";

// import Notes from "./Containers/Markdown/";
// import Create from "./Containers/Books/Create.js";
// import Display from "./Containers/Books/Display.js";
// Import other components like OpenAI, Search, Alerts, etc.

const DashboardLayout = ({ onLogout }) => {
  // const [toggleSidebar, setToggleSidebar] = useState(false);

  const location = useLocation(); // Hook to get the current location

  //Function to determine which sidebar to display
  const getSidebarComponent = () => {
    switch (location.pathname) {
      // case "/search":
      //   return <SidebarSearch />;
      // case "/todos":
      //   return <SidebarTodo />;
      // case "/mail":
      //   return <SidebarMail />;
      // case "/books":
      //   return <SidebarBooks />;
      // case "/books/display":
      //   return <SidebarDisplay />;
      // Add more cases as needed for different routes
      default:
        return <Sidebar />;
    }
  };

  return (
    <div id="wrapper">
      {getSidebarComponent()}
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Topbar onLogout={onLogout} />
          <div className="container-fluid">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/todos" element={<Todos />} />
              <Route path="/openai" element={<OpenAI />} />
              <Route path="/search" element={<Search />} />
              <Route path="/mail" element={<Mail />} />
              <Route path="/books" element={<Books />} />
              <Route path="/wizard" element={<Wizard />} />
              <Route path="/display" element={<Display />} />
              <Route path="/completions" element={<Completions />} />
              <Route path="/metrics" element={<Metrics />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="*" element={<Navigate replace to="/home" />} />
            </Routes>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
};

const DashboardPage = ({ onLogout }) => (
  <Router>
    <DashboardLayout onLogout={onLogout} />
  </Router>
);

export default DashboardPage;
