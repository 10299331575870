import React from "react";
import { Form, Label, Input } from "reactstrap";
const CreateProjectNameForm = ({ onChange, projectData }) => {
  return (
    <Form>
      <Label>
        Project Name:
        <Input
          type="text"
          name="title"
          value={projectData.title}
          onChange={onChange}
        />
      </Label>
    </Form>
  );
};

export default CreateProjectNameForm;
