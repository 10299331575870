import React, { useState, useEffect } from "react";
import config from "../../config";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Container,
  Alert,
} from "reactstrap";

const CreateBooks = () => {
  // State for each of the form fields
  const [illustrations, setIllustrations] = useState("");
  const [modal, setModal] = useState(false);
  const [completion, setCompletion] = useState("");
  const [title, setTitle] = useState(""); // Default title
  const [target, setTarget] = useState(""); // Default target age group
  const [characters, setCharacters] = useState([""]); // Default characters
  const [theme, setTheme] = useState(""); // Default theme
  const [targetEmotions, setTargetEmotions] = useState([]); // Default target emotions
  const [directive, setDirective] = useState(""); // Empty, as this may require specific input
  const [action, setAction] = useState([""]); // Default action
  const [pageCount, setPageCount] = useState(12); // Default page count
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (completion) {
      setModal(true);
    }
  }, [completion]); // Only re-run the effect if `completion` changes

  // Toggling the modal
  const toggle = () => setModal(!modal);

  // Handlers for changing each state
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "description":
        setDescription(value);
        break;
      case "illustrations":
        setIllustrations(value);
        break;
      case "title":
        setTitle(value);
        break;
      case "target":
        setTarget(value);
        break;
      case "characters":
        // Assuming the input is a comma-separated string
        setCharacters(value.split(",").map((character) => character.trim()));
        break;
      case "theme":
        setTheme(value);
        break;
      case "targetEmotions":
        // Assuming multiple selection is handled similarly to characters
        setTargetEmotions(value.split(",").map((emotion) => emotion.trim()));
        break;
      case "directive":
        setDirective(value);
        break;
      case "action":
        // Assuming action are entered as a newline-separated string
        setAction(value.split("\n").map((step) => step.trim()));
        break;
      case "pageCount":
        setPageCount(parseInt(value, 10));
        break;
      default:
        break;
    }
  };

  const handleSaveToFile = () => {
    const element = document.createElement("a");
    const file = new Blob([completion], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "book.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Assuming the form data is sent to the server
    const formData = {
      illustrations,
      title,
      target,
      characters,
      theme,
      targetEmotions,
      directive,
      action,
      pageCount,
      description: "A book about a new baby sister",
    };
    console.log("Form data:", formData);
    try {
      const response = await fetch(`${config.apiServer_gpt}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      setCompletion(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  // Example form rendering
  return (
    <Container>
      <p>
        This is where we define the properties of the story. The descriptions
        tends to be the most important part. However, note that this form is
        going to create the prompt which will do the work.{" "}
      </p>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="illustrations" style={{ marginRight: "30px" }}>
            Illustrations:
          </Label>
          <Input
            type="radio"
            id="illustrationsYes"
            name="illustrations"
            value="true"
            checked={illustrations === "true"}
            onChange={handleInputChange}
            style={{ marginRight: "5px" }}
          />
          <Label for="illustrationsYes" style={{ marginRight: "30px" }}>
            Yes
          </Label>
          <Input
            type="radio"
            id="illustrationsNo"
            name="illustrations"
            value="false"
            checked={illustrations === "false"}
            onChange={handleInputChange}
            style={{ marginRight: "5px" }}
          />
          <Label for="illustrationsNo">No</Label>
        </FormGroup>
        <FormGroup>
          <Label for="description">This book is about?</Label>
          <Input
            type="textarea"
            name="description"
            id="description"
            value={description}
            onChange={handleInputChange}
            placeholder="e.g., A New baby sister is coming into a home with an older sibling. The older sibling is excited but also worried about sharing their toys and parents' attention. The book is about how the older sibling learns to share and love their new baby sister."
          />
        </FormGroup>
        <FormGroup>
          <Label for="title">Title:</Label>
          <Input
            type="text"
            name="title"
            id="title"
            value={title}
            onChange={handleInputChange}
            placeholder="e.g., Hi New Baby Sister!"
          />
        </FormGroup>
        <FormGroup>
          <Label for="target">Target:</Label>
          <Input
            type="select"
            id="target"
            name="target"
            value={target}
            onChange={handleInputChange}
          >
            <option value="0">1-3</option>
            <option value="1">4-5</option>
            <option value="2">6-8</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="characters">Characters:</Label>
          <Input
            type="text"
            name="characters"
            id="characters"
            value={characters.join(", ")}
            onChange={handleInputChange}
            placeholder="e.g., Animals, children, parents, fantasy creatures, Action figures, etc."
          />
        </FormGroup>
        <FormGroup>
          <Label for="theme">Theme:</Label>
          <Input
            type="text"
            name="theme"
            id="theme"
            value={theme}
            onChange={handleInputChange}
            placeholder="e.g., new sibling in the home"
          />
        </FormGroup>
        <FormGroup>
          <Label for="targetEmotions">Target Emotions:</Label>
          <Input
            type="text"
            name="targetEmotions"
            id="targetEmotions"
            value={targetEmotions.join(", ")}
            onChange={handleInputChange}
            placeholder="e.g., sharing, jealousy"
          />
        </FormGroup>
        <FormGroup>
          <Label for="directive">Directive:</Label>
          <Input
            type="text"
            name="directive"
            id="directive"
            value={directive}
            onChange={handleInputChange}
            placeholder="e.g., ways to communicate addressing the target emotions"
          />
        </FormGroup>
        <FormGroup>
          <Label for="action">Action:</Label>
          <Input
            type="textarea"
            name="action"
            id="action"
            value={action.join("\n")}
            onChange={handleInputChange}
            placeholder="e.g., providing a safe space for the older child's favorite toys"
          />
        </FormGroup>
        <FormGroup>
          <Label for="pageCount">Page Count:</Label>
          <Input
            type="number"
            name="pageCount"
            id="pageCount"
            value={pageCount}
            onChange={handleInputChange}
          />
        </FormGroup>
        {/* Placeholder for managing pages */}
        <Button type="submit" outline color="primary">
          Submit
        </Button>
      </Form>
      {completion && (
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Completion</ModalHeader>
          <ModalBody>
            <Alert color="success">
              <pre>
                <code>{completion}</code>
              </pre>
            </Alert>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              Close
            </Button>
            <Button
              style={{ backgroundColor: "transparent", color: "black" }}
              onClick={handleSaveToFile}
            >
              Save to file
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Container>
  );
};

export default CreateBooks;
